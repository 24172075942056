import { SbBlokData, storyblokEditable, useStoryblokApi} from "@storyblok/react";

type TProps = {
    blok: {
        title: string,
        subtitle: string,
        video: string
    } & SbBlokData
}

export default function Youtube(props: TProps) {
    const {blok} = props;

    const videoId = blok.video.split('v=')[1];
    // Embed youtube video
    // https://www.youtube.com/embed/VIDEO_ID
    const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0`;

    return (
        <div className={'mx-auto padding-normal max-w-7xl'} {...storyblokEditable(blok)}>
            <div

                className=" pb-12 px-6 sm:pb-12 lg:px-8">
                <div className="mx-auto max-w-2xl text-center"
                     data-aos={'fade-in'}
                >
                    <h3 className={'text-2xl sm:text-5xl ' + " font-bold tracking-tight text-gray-900"}>
                        {blok.title}
                    </h3>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        {blok.subtitle}
                    </p>

                </div>
            </div>


            <div className={'max-w-3xl mx-auto'}>
                <div className="relative w-full aspect-video rounded-lg">
                    <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src={embedUrl}
                    ></iframe>
                </div>
            </div>

        </div>
    )
}

