import '../styles/tailwind.css'
import '../styles/globals.css'

// import '../styles/_timeline.scss'
import '../styles/_process-timeline.scss'

import '../styles/swiper.min.css'

import type {AppProps} from 'next/app'

import {storyblokInit, apiPlugin} from "@storyblok/react";

import React, {useEffect} from "react";
import dynamic from "next/dynamic";

const Page = dynamic(() => import('../components/StoryblokPage'),);

const Grid = dynamic(() => import('../components/layout/Grid'),);

const PrimaryFeatures = dynamic(() => import('../components/features/PrimaryFeatures'),);

const Gallery = dynamic(() => import('../components/gallery/Gallery'),);
// import ContactForm from "../components/functional/ContactForm";
// import ContactFormMini from "../components/functional/ContactFormMini";

const ContactForm = dynamic(() => import('../components/functional/ContactForm'),)
const ContactFormMini = dynamic(() => import('../components/functional/ContactFormMini'),)


import Youtube from "../components/functional/Youtube";

const FAQData = dynamic(() => import("../components/functional/FAQData"),);

// import TechStack from "../components/content/TechStack";

const TechStack = dynamic(() => import('../components/content/TechStack'),)


import HtmlBlock from "../components/layout/HtmlBlock";

// import Code from "../components/content/Code";
const Code = dynamic(() => import('../components/content/Code'),)


const TwoColumnQuoteSection = dynamic(() => import("../components/sections/TwoColumnQuoteSection"),);
const TwoColumnImageAndStats = dynamic(() => import("../components/sections/TwoColumnImageAndStats"),);
const SingleImage = dynamic(() => import("../components/gallery/SingleImage"),);
const SimpleTimeline = dynamic(() => import("../components/content-layout/SimpleTimeline"),);
const ProjectTimeline = dynamic(() => import("../components/functional/ProjectTimeline"),);
import {AppWrapper} from '../context/state.js';

const SimpleHeader = dynamic(() => import("../components/headers/SimpleHeader"),);
const SuccessStory = dynamic(() => import("../components/sections/SuccessStory"),);
const SplitWithImage = dynamic(() => import("../components/content-layout/SplitWithImage"),);
const SimpleCTA = dynamic(() => import("../components/sections/SimpleCTA"),);
const Padding = dynamic(() => import("../components/sections/Padding"),);
const SuccessStoriesSmall = dynamic(() => import('../components/functional/SuccessStoriesSmall'),);
const CardGrid = dynamic(() => import("../components/features/CardGrid"),);
import {Container} from "../components/functional/Container";

const TwoColumnOffset = dynamic(() => import("../components/sections/TwoColumnOffset"),);
const InlineHeading = dynamic(() => import("../components/inline/InlineHeading"),);
const InlineFeatures = dynamic(() => import("../components/inline/InlineFeatures"),);
const InlineParagraph = dynamic(() => import("../components/inline/InlineParagraph"),);
const InlineImage = dynamic(() => import("../components/inline/InlineImage"),);
const Project = dynamic(() => import("../components/project-history/Project"),);
const ImageHero = dynamic(() => import("../components/heros/ImageHero"),);
const QuoteSection = dynamic(() => import("../components/sections/QuoteSection"),);
const Testimonials = dynamic(() => import("../components/content/Testimonials"),);
const InlineButtons = dynamic(() => import("../components/inline/InlineButtons"),);
const OfficeLocation = dynamic(() => import("../components/content/OfficeLocation"),);
import {useRouter} from "next/router";

const Blogpost = dynamic(() => import("../components/blog/Blogpost"),);
const LegacyParagraph = dynamic(() => import("../components/blog/LegacyParagraph"),);
const Breadcrumbs = dynamic(() => import("../components/layout/Breadcrumbs"),);
const Incentive3Column = dynamic(() => import("../components/sections/Incentive3Column"),);
const TextHero = dynamic(() => import("../components/heros/TextHero"),);
const StyledMap = dynamic(() => import("../components/functional/StyledMap"),);
const SecondaryHero = dynamic(() => import("../components/heros/SecondaryHero"),);
const LandingPageHero = dynamic(() => import("../components/heros/LandingPageHero"),);
const LandingPageHeroGuide = dynamic(() => import("../components/heros/LandingPageHeroGuide"),);

import Head from "next/head";
import Script from "next/script";
const ReviewCarousel = dynamic(() => import("../components/functional/ReviewCarousel"),);

const Schedule = dynamic(() => import("../components/functional/Schedule"),);
const Relationship = dynamic(() => import("../components/functional/Relationship"),);
const FixedLandingPageCTA = dynamic(() => import("../components/functional/FixedLandingPageCTA"),);
// const CallMeBack = dynamic(() => import("../components/internal/CallMeBack"),);
const VideoHero = dynamic(() => import("../components/heros/VideoHero"),);
const ContentSection = dynamic(() => import("../components/content-layout/ContentSection"),);
const ProvenProcess = dynamic(() => import("../components/content/ProvenProcess"),);
const TwoColumnContentSection = dynamic(() => import("../components/sections/TwoColumnContentSection"),);
const ProjectCalculator = dynamic(() => import("../components/project-calculator/ProjectCalculator"),);
const RevealFeatures = dynamic(() => import("../components/features/RevealFeatures"),);
const SquareBoxFeatures = dynamic(() => import("../components/features/SquareBoxFeatures"),);

const SuccessStorySquares = dynamic(() => import("../components/functional/SuccessStorySquares"));
const Logos = dynamic(() => import("../components/functional/Logos"));

// import { Inter_Tight} from 'next/font/google'

storyblokInit({
    accessToken: "9TlCjRXcyzBJO160uY0EUAtt",
    apiOptions: {
        cache: {
            clear: "auto",
            type: "memory",
        },
    },
    use: [apiPlugin],
    // bridge: false,
    // apiOptions: {
    //   region: "us", // Pass this key/value if your space was created under US region
    // },
    components: {
        SecondaryHero,
        LandingPageHero,
        LandingPageHeroGuide,
        ReviewCarousel,
        VideoHero,
        Grid,
        Logos,
        "page": Page,
        PrimaryFeatures,
        Gallery,
        ContactForm,
        ContactFormMini,
        TechStack,
        ProvenProcess,
        SquareBoxFeatures,
        HtmlBlock,
        TwoColumnQuoteSection,
        TwoColumnImageAndStats,
        TwoColumnContentSection,
        ContentSection,
        SingleImage,
        Code,
        SimpleTimeline,
        ProjectTimeline,
        Incentive3Column,
        TextHero,
        StyledMap,
        ProjectCalculator,
        SimpleHeader,
        SuccessStory,
        SplitWithImage,
        CardGrid,
        SimpleCTA,
        Padding,
        SuccessStoriesSmall,
        SuccessStorySquares,
        Container,
        TwoColumnOffset,
        InlineHeading,
        InlineFeatures,
        InlineParagraph,
        InlineImage,
        'project': Project,
        ImageHero,
        QuoteSection,
        Testimonials,
        InlineButtons,
        RevealFeatures,
        'blogpost': Blogpost,
        'paragraph': LegacyParagraph,
        Breadcrumbs,
        Youtube,
        FAQData,
        OfficeLocation,
        Schedule,
        Relationship,
        FixedLandingPageCTA
    }
});


// If loading a variable font, you don't need to specify the font weight
// const inter = Inter_Tight({
//     subsets: ['latin'],
//     display: 'swap',
// })

/**
 * List of classes to keep from being purged
 * btn-primary-xs
 */

export default function App({Component, pageProps}: AppProps) {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url: URL) => {
            window.scrollTo({top: 0, behavior: "smooth"});


            try {
                // @ts-ignore
                // window.gtag.pageview(url);
            } catch (e) {
            }

            if (typeof sessionStorage !== 'undefined') {
                const visitedPages = sessionStorage.getItem('pages')
                    ? JSON.parse(sessionStorage.getItem('pages') as string)
                    : [];
                visitedPages.push(window.location.href);
                sessionStorage.setItem('pages', JSON.stringify(visitedPages));
            }

            // @ts-ignore
            if (window.lazyloadInstance) {
                // IntersectionObserver initialization code
                // @ts-ignore
                window.lazyloadInstance.update();
            }

            setTimeout(() => {
                // @ts-ignore
                if (window.lazyloadInstance) {
                    // IntersectionObserver initialization code
                    // @ts-ignore
                    window.lazyloadInstance.update();
                }
            }, 1000)
        };

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };

    }, [router.events])

    let isPreview = false;
    if (typeof window !== 'undefined') {
        isPreview = window.location.href.includes('preview');
    }

    if (typeof window !== 'undefined' && !isPreview) {
        if (typeof sessionStorage !== 'undefined') {
            if (!sessionStorage.getItem('entrypage')) {
                const date = new Date();
                sessionStorage.setItem('entrypage', window.location.href);
                sessionStorage.setItem('entrydate', date.toISOString());
            }

            // get utm param utm_campaign
            const urlParams = new URLSearchParams(window.location.search);
            const utmCampaign = urlParams.get('utm_campaign');
            if (utmCampaign) {
                sessionStorage.setItem('utm_campaign', utmCampaign);
            }
        }
    }

    return (
        <AppWrapper>
            {!isPreview && (<>
                {/*                <Script id={'hotjar'} strategy="afterInteractive">*/}
                {/*                    {`*/}
                {/*                    (function(h,o,t,j,a,r){*/}
                {/*                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};*/}
                {/*                    h._hjSettings={hjid:2872654,hjsv:6};*/}
                {/*                    a=o.getElementsByTagName('head')[0];*/}
                {/*                    r=o.createElement('script');r.async=1;*/}
                {/*                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;*/}
                {/*                    a.appendChild(r);*/}
                {/*                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');*/}
                {/*`}*/}
                {/*                </Script>*/}
                {/*                <Script strategy="afterInteractive" id="hs-script-loader" async defer*/}
                {/*                        src="//js-eu1.hs-scripts.com/25424578.js"/>*/}

                {/*<Script strategy="afterInteractive"*/}
                {/*        src="https://www.googletagmanager.com/gtag/js?id=AW-10838573329"></Script>*/}
                {/*<Script id={'aw'} strategy="afterInteractive">{`*/}
                {/*window.dataLayer = window.dataLayer || [];*/}
                {/*function gtag(){dataLayer.push(arguments);}*/}
                {/*    */}
                {/*setTimeout(() => {*/}
                {/*    gtag('js', new Date());*/}

                {/*    gtag('config','AW-10838573329');*/}
                {/*    }, 3500);*/}
                {/*    `}*/}
                {/*</Script>*/}

            </>)}

            <Script id={'gtag'} strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-PRN9L1LVCT"/>
            <Script id={'gtag-after'}>
                {`
          window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-PRN9L1LVCT');
                    gtag('config', 'AW-10838573329');
        `}
            </Script>


            <Head>
                <link rel={'stylesheet'} href="/toastify.css"/>
                <link rel={'stylesheet'} href="/carousel/styles.css"/>
                {/*<link rel="preconnect" href="https://fonts.googleapis.com" />*/}
                {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={'crossOrigin'} />*/}
                {/*<link*/}
                {/*    rel="stylesheet"*/}
                {/*    href={'https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;300;500;600&display=swap'} />*/}

                <link rel="dns-prefetch" href="https://www.googletagmanager.com/"/>
                {/*<link href="https://www.googletagmanager.com/gtag/js?id=G-PRN9L1LVCT" rel="preload" as="script"/>*/}

                <link rel="stylesheet" href="https://use.typekit.net/nrq0xne.css"/>

                <script dangerouslySetInnerHTML={{
                    __html: `
                     (function(h,o,t,j,a,r){
                    h.hj = h.hj || function () {
                        (h.hj.q = h.hj.q || []).push(arguments)
                    };
                    h._hjSettings={hjid:2872654,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `
                }}></script>


                {/*<script*/}
                {/*    dangerouslySetInnerHTML={{*/}
                {/*        __html: `*/}
                {/*        window.addEventListener('DOMContentLoaded', () => {*/}
                {/*        window.onload = function() {*/}

                {/*            window.hotjarLoaded = false;*/}
                {/*            */}
                {/*            const loadhj = (event) => {*/}
                {/*               if(window.hotjarLoaded) {*/}
                {/*                    return;*/}
                {/*                }*/}
                {/*                */}
                {/*           // Add google script*/}
                {/*           // https://www.googletagmanager.com/gtag/js?id=AW-10838573329*/}
                {/*           //  const gaScript = document.createElement('script');*/}
                {/*           //  gaScript.setAttribute('type', 'text/javascript');*/}
                {/*           //  gaScript.setAttribute('async', '');*/}
                {/*           //  gaScript.setAttribute('defer', '');*/}
                {/*           //  gaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=AW-10838573329');*/}
                {/*           //  gaScript.onload = () => {*/}
                {/*           //      window.dataLayer = window.dataLayer || [];*/}
                {/*           //      function gtag(){dataLayer.push(arguments);}*/}
                {/*           //      gtag('js', new Date());*/}
                {/*           //      gtag('config', 'AW-10838573329');*/}
                {/*           //      window.gtag = gtag;*/}
                {/*           //  }*/}
                {/*           //  document.head.appendChild(gaScript);*/}
                {/*           //*/}
                {/*          */}
                {/*               */}
                {/*                window.hotjarLoaded = true;*/}
                {/*                */}
                {/*                (function(h,o,t,j,a,r){*/}
                {/*                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};*/}
                {/*                        h._hjSettings={hjid:2872654,hjsv:6};*/}
                {/*                        a=o.getElementsByTagName('head')[0];*/}
                {/*                        r=o.createElement('script');r.async=1;*/}
                {/*                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;*/}
                {/*                        a.appendChild(r);*/}
                {/*                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');*/}
                {/*                */}
                {/*            }*/}
                {/*            */}
                {/*            document.addEventListener("scroll", loadhj);*/}
                {/*      */}
                {/*    }*/}
                {/*      */}
                {/*        });*/}
                {/*`,*/}
                {/*    }}*/}
                {/*></script>*/}

                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                            {
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                "url": "https://www.pacificcodeline.com",
                                "logo": "https://www.pacificcodeline.com/images/SVG/logo.svg"
                            }
                        )
                    }}/>

            </Head>

            <Component key={'mainComponent'} {...pageProps}/>

            {/*<CallMeBack/>*/}

            <script
                dangerouslySetInnerHTML={{
                    __html: ` adroll_adv_id = "FEVY6NVNCZG6DK6JZTLPIU"; adroll_pix_id = "7R3JW7SCRRE7RIONN7GGIZ"; adroll_version = "2.0";  (function(w, d, e, o, a) { w.__adroll_loaded = true; w.adroll = w.adroll || []; w.adroll.f = [ 'setProperties', 'identify', 'track', 'identify_email' ]; var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js"; for (a = 0; a < w.adroll.f.length; a++) { w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) { return function() { w.adroll.push([ n, arguments ]) } })(w.adroll.f[a]) }  e = d.createElement('script'); o = d.getElementsByTagName('script')[0]; e.async = 1; e.src = roundtripUrl; o.parentNode.insertBefore(e, o); })(window, document); adroll.track("pageView"); 
                        `
                }}/>
        </AppWrapper>
    )
}
