import {storyblokEditable, useStoryblokApi} from "@storyblok/react";
import classNames from "classnames";

export default function HtmlBlock({blok}: { blok: any }) {
    const storyblokApi = useStoryblokApi()

    return <div className={blok?.cssClass}  {...storyblokEditable(blok)}>
        <div className={classNames({"mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 rich-text": blok.container})}>
            <div
                dangerouslySetInnerHTML={{
                    __html: (blok.text)
                }}
            />
        </div>
    </div>

}