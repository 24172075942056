import {SbBlokData, StoryblokComponent, storyblokEditable} from "@storyblok/react";

interface IProps {
    blok: { class: string, blocks: Array<any> } & SbBlokData
}

export function Container(props: IProps) {
    return <div className={props.blok.class} {...storyblokEditable(props.blok)}>
        {props.blok.blocks.map((nestedBlok: any) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </div>
}